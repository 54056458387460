.registerContainer {
    width: 300px;
    height: 250px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.logo {
    color: slateblue;
    display: flex;
    align-items: center;
    font-weight: 700;
}

input:focus {
    outline: none;
}

.registerButton {
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
    background-color: slateblue;
    cursor: pointer;
}

.success {
    color: green;
    font-size: 12px;
    text-align: center;
}

.failure {
    color: red;
    font-size: 12px;
    text-align: center;
}

.registerCancel {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
