.card {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cardEdit {
    align-self: flex-end;
    font-size: 16px;
    margin-top: 10px;
}

.cardIcon {
    margin-left: 10px;
    cursor: pointer;
}

.cardIcon:first-child {
    color: teal;
}

.cardIcon:last-child {
    color: tomato;
}

label {
    width: max-content;
    color: tomato;
    font-size: 13px;
    border-bottom: 0.5px solid tomato;
    margin: 3px 0;
}

.desc {
    font-size: 14px;
}

.star {
    color: gold;
}

.username {
    font-size: 14px;
}

.date {
    font-size: 12px;
}

form {
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.updateForm {
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.updateButton {
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
    background-color: teal;
    cursor: pointer;
}

input, textarea {
    border: none;
    border-bottom: 1px solid gray;
}

input::placeholder, textarea::placeholder {
    font-size: 12px;
    color: rgb(172, 169, 169);
}

.submitButton {
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
    background-color: tomato;
    cursor: pointer;
}

.button {
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.logout {
    background-color: tomato;
}

.login {
    background-color: teal;
    margin-right: 10px;
}

.register {
    background-color: slateblue;
}

.buttons, .logout {
    position: absolute;
    top: 10px;
    right: 10px;
}

.popup {
    z-index: 999;
}